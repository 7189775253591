/** OPACITY */
$noOpacity: 0;
$fullOpacity: 1;
$mediumOpacity: 0.4;
$lowOpacity: 0.2;

/** BREAK POINT */
$mobileBreakPoint: 991px;
$desktopBreakPoint: 992px;
$DesktopLargeBreakPoint: 1199px;

/** LAYOUT */
$centralFixedColumn: 1080px;
$centralFixedLargeColumn: 1200px;

/** COLOR */
$white: #fff;
$purple: #2e1d59;
$purpleTransparent: unquote('#2E1D591A');
$darkPurple: #1a1033;
$mediumDarkPurple: #251847;
$lightPurple: #f8fbfc;
$grey: #757575;
$lightGrey: #f5f5f5;
$mediumGrey: #e4e4e4;
$pink: #fc356b;
$blue: #47fef1;
$yellow: #e5ff63;
$green: #8cfeb3;
$lime: #b1fd8c;
$shadow_small: rgba(46, 29, 89, $lowOpacity);
$shadow_large: rgba(46, 29, 89, $lowOpacity);
$linearPurple: linear-gradient(45deg, $purple 0%, #402e6e 100%);
$linearPink: linear-gradient(45deg, $pink 0%, #fdca89 100%);
$linearGreenBlue: linear-gradient(45deg, $yellow 0%, $blue 100%);
$linearBlue: linear-gradient(45deg, #75feda 0%, #cafffb 100%);
$linearOrange: linear-gradient(45deg, #f4957a 0%, $pink 100%);
$linearGreen: linear-gradient(45deg, $lime 0%, $yellow 100%);

/** SIZE */

$text_size: 12pt;
$text_size_mobile: 12pt;

$text_line_height: 1.8;
$text_line_height_small: 1.2;
$text_line_height_large: 2;

$text_line_height_mobile: 1.8;

$xxsmall: 9pt;
$xxsmall_mobile: 8pt;

$xsmall: 10pt;
$xsmall_mobile: 10pt;

$small: 13pt;
$small_mobile: 12pt;

$rarelyused: 20pt;
$rarelyused_mobile: 17pt;

$homepage_title_mobile: 24pt;
$homepage_title_desktop: 50pt;

$medium: 28pt;
$medium_mobile: 22pt;

$large: 30pt;
$large_mobile: 26pt;

$xlarge: 54pt;
$xlarge_mobile: 40pt;

$jumbo: 120pt;
$jumbo_mobile: 120pt;

$mobileHeaderHeight: 82px;
$desktopHeaderHeight: 110px;

/** LETTER-SPACING */

$LSsmall: -25px;

/* FONT-WEIGHT */

$fontWeightLight: 300;
$fontWeightBook: 500;
$fontWeightBold: 700;
